import common from "./common";
const baseUrl = common.baseUrl;

export default {
  // 登录验证
  sellerLogin: `${baseUrl}Seller/login`, // 登录
  sellerLogout: `${baseUrl}Seller/logout`, // 登出
  sellerCheckLogin: `${baseUrl}Seller/check_login`, // 检测是否登录
  sellerSms: `${baseUrl}Seller/password/sms_code`, // 验证码
  sellerReset: `${baseUrl}Seller/password/forget_password`, // 重置密码

  // 商家菜单
  sellerMenus: `${baseUrl}Seller/menus`,

  // 规格属性
  sellerGoodsAttrs: `${baseUrl}Seller/goods_attrs`,

  // 商品管理
  sellerGoods: `${baseUrl}Seller/goods`,
  sellerStoreGoodsClasses: `${baseUrl}Seller/store_goods_classes`, // 获取店铺有权的栏目信息
  sellerGoodsUpload: `${baseUrl}Seller/goods/upload/images`,
  sellerBatch: `${baseUrl}Seller/goods_batch_update`,

  // 编辑器上传
  sellerEditor: `${baseUrl}Seller/editor/upload`,

  // oss上传配置
  sellerOss: `${baseUrl}Seller/configs/index`,

  // 批量上传文件路径
  sellerOssUrl: `${baseUrl}Seller/goods/upload_zip`,

  // 证件上传
  upLoadFiles: `${baseUrl}Seller/uploads/upload/files`,

  // 品牌管理
  sellerGoodsBrands: `${baseUrl}Seller/goods_brands`,

  // 银行代码
  bankCode: `${baseUrl}Seller/bankCode`,
  // 订单管理
  sellerOrders: `${baseUrl}Seller/orders`,
  sellerOrderExport: `${baseUrl}Seller/order/extends/export`,
  sellerOrderRefund: `${baseUrl}Seller/order/extends/order_goods_refunds`,

  // 皇晶订单
  sellerIntegralOrders: `${baseUrl}Seller/integral_order`,

  // 订单售后
  sellerRefunds: `${baseUrl}Seller/refunds`,

  // 物流公司
  sellerExpresses: `${baseUrl}Seller/expresses`,

  // 订单评论
  sellerOrderComments: `${baseUrl}Seller/order_comments`,

  // 配置中心处理
  sellerConfigs: `${baseUrl}Seller/configs`,

  // 实名认证
  corpMember: `${baseUrl}Seller/adapay_user_member/edit`,

  // 个人结算
  userEdit: `${baseUrl}Seller/adapay_user_member/add_user_corp`,

  // 企业结算
  enterpriseEdit: `${baseUrl}Seller/corp_member/add`,

  // 获取商家认证信息详情
  sellerAuthentication: `${baseUrl}Seller/corpMember/info`,

  // 获取个人认证信息详情
  userInfo: `${baseUrl}Seller/adapay_user_member/get_user_corp_info`,

  documentsBank: `${baseUrl}Seller/bank`,

  // 获取省级code
  getOneLevel: `${baseUrl}Seller/oneLevel`,

  // 获取地区code
  getSecondaryLevel: `${baseUrl}Seller/secondaryLevel`,

  // 所有分类
  sellerAllClass: `${baseUrl}Seller/store_class_apply/cate`,
  // 店铺分类设置
  sellerStoreClass: `${baseUrl}Seller/store_class_apply`,

  // 自提中心处理
  sellerAddress: `${baseUrl}Seller/store_address`,

  // 全国省市区获取
  sellerAreas: `${baseUrl}Seller/areas`,

  // 运费配置
  sellerFreights: `${baseUrl}Seller/freights`,

  // 分销活动
  sellerDistributions: `${baseUrl}Seller/distributions`,

  // 分销日志
  sellerDistributionLogs: `${baseUrl}Seller/distribution_logs`,

  // 优惠券
  sellerCoupons: `${baseUrl}Seller/coupons`,
  sellerCouponLogs: `${baseUrl}Seller/coupon_logs`, // 优惠券日志

  // 满减
  sellerFullReductions: `${baseUrl}Seller/full_reductions`,

  // 特价
  sellerSeckills: `${baseUrl}Seller/seckills`,
  // 批量修改特价时间
  sellerSeckillsUpdate: `${baseUrl}Seller/seckills/batch_update`,

  // 拼团
  sellerCollectives: `${baseUrl}Seller/collectives`,
  sellerCollectiveLogs: `${baseUrl}Seller/collective_logs`,

  // 结算日志
  sellerOrderSettlements: `${baseUrl}Seller/order_settlements`,

  // 店铺资金日志
  sellerMoneyLogs: `${baseUrl}Seller/money_logs`,

  // 资金解冻
  sellerMoneyThaw: `${baseUrl}Seller/statistic/thaw_money`,

  // 协议
  sellerInfo: `${baseUrl}Seller/statistic/info`,

  // 资金提现
  sellerCashes: `${baseUrl}Seller/cashes`,
  // 皇晶转让
  editIntegral: `${baseUrl}Seller/configs/edit_integral`,
  sellerBank: `${baseUrl}Seller/user_bank`,
  getHuangj: `${baseUrl}Seller/get_store_store_integral`,

  // 数据统计
  sellerStatistics: `${baseUrl}Seller/statistics`,
};
