export default [
  // 登录
  {
    path: "/Seller/login",
    name: "seller_login",
    component: () => import("@/views/Seller/login-seller"),
  },

  // 后台界面
  {
    path: "/Seller/index",
    name: "seller_index",
    component: () => import("@/views/Seller/index-seller"),
    children: [
      // 默认页面
      {
        path: "/Seller/index",
        name: "seller_default",
        component: () => import("@/views/Seller/default-seller"),
      },

      // 配置中心
      {
        path: "/Seller/configs",
        name: "seller_configs",
        component: () => import("@/views/Seller/configs/index-config"),
      },

      // 自提配置
      {
        path: "/Seller/address",
        name: "seller_address",
        component: () => import("@/views/Seller/address/index-address"),
      }, // 自提列表
      {
        path: "/Seller/address/form/:id?",
        name: "seller_address_form",
        component: () => import("@/views/Seller/address/form-address"),
      }, // 自提编辑

      // 店铺资金日志
      {
        path: "/Seller/money_logs",
        name: "seller_money_logs",
        component: () => import("@/views/Seller/money_logs/index-money"),
      },

      // 资金提现
      {
        path: "/Seller/cashes",
        name: "seller_cashes",
        component: () => import("@/views/Seller/cashes/index-cashes"),
      },
      {
        path: "/Seller/cashes/form",
        name: "seller_cashes_form",
        component: () => import("@/views/Seller/cashes/form-cashes"),
      },

      // 实名认证
      {
        path: "/Seller/authentication",
        name: "seller_authentication",
        component: () => import("@/views/Seller/authentication/index-anthent"),
      },
      // 黄晶转让
      {
        path: "/Seller/transference",
        name: "seller_transference",
        component: () => import("@/views/Seller/transference/index-tran"),
      },

      // 商品管理
      {
        path: "/Seller/goods",
        name: "seller_goods",
        component: () => import("@/views/Seller/goods/index-goods"),
      }, // 商品列表
      {
        path: "/Seller/goods/chose_class/:id?",
        name: "seller_goods_chose_class",
        component: () => import("@/views/Seller/goods/class-goods"),
      }, // 商品分类选择
      {
        path: "/Seller/goods/form/:id?",
        name: "seller_goods_form",
        component: () => import("@/views/Seller/goods/form-goods"),
      }, // 商品编辑
      {
        path: "/Seller/goods/notice",
        name: "seller_goods_notice",
        component: () => import("@/views/Seller/goods/notice-goods"),
      }, // 商品发布须知

      // 分类申请
      {
        path: "/Seller/goods_class",
        name: "seller_goods_class",
        component: () => import("@/views/Seller/goods_class/index-class"),
      }, // 列表
      {
        path: "/Seller/goods_class/form/:id?",
        name: "seller_goods_class_form",
        component: () => import("@/views/Seller/goods_class/form-class"),
      }, // 编辑

      // 属性规格
      {
        path: "/Seller/goods_attrs",
        name: "seller_goods_attrs",
        component: () => import("@/views/Seller/goods_attrs/index-attrs"),
      }, // 商品列表
      {
        path: "/Seller/goods_attrs/form/:id?",
        name: "seller_goods_attrs_form",
        component: () => import("@/views/Seller/goods_attrs/form-attrs"),
      }, // 商品编辑

      // 订单管理
      {
        path: "/Seller/orders",
        name: "seller_orders",
        component: () => import("@/views/Seller/orders/index-order"),
      },
      {
        path: "/Seller/orders/form/:id?",
        name: "seller_orders_form",
        component: () => import("@/views/Seller/orders/form-order"),
      },

      // 皇晶订单管理
      {
        path: "/Seller/integral_orders",
        name: "seller_integral_orders",
        component: () => import("@/views/Seller/integral_orders/index-order"),
      },
      {
        path: "/Seller/integral_orders/form/:id?",
        name: "seller_integral_orders_form",
        component: () => import("@/views/Seller/integral_orders/form-order"),
      },

      // 售后管理
      {
        path: "/Seller/refund",
        name: "seller_refund",
        component: () => import("@/views/Seller/refunds/index-refund"),
      },
      {
        path: "/Seller/refund/form/:id?",
        name: "seller_refund_form",
        component: () => import("@/views/Seller/refunds/form-refund"),
      },

      // 配送运费
      {
        path: "/Seller/freights/form",
        name: "seller_freights",
        component: () => import("@/views/Seller/freights/form-freight"),
      },

      // 特价
      {
        path: "/Seller/seckills",
        name: "seller_seckills",
        component: () => import("@/views/Seller/seckills/index-seckill"),
      },
      {
        path: "/Seller/seckills/form/:id?",
        name: "seller_seckills_form",
        component: () => import("@/views/Seller/seckills/form-seckill"),
      },

      // 在线聊天
      {
        path: "/Seller/chats",
        name: "seller_chats",
        component: () => import("@/views/Seller/chats/index-chat"),
      },

      // 数据统计
      {
        path: "/Seller/statistics/order",
        name: "seller_statistics_order",
        component: () => import("@/views/Seller/statistics/order-statistics"),
      },
    ],
  },
];
